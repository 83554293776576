import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const Metadata = ({ title, description }) => (
  <Helmet>
    <title>{title} | Li Tzi-Mei</title>
    <meta name="description" content={description} />
    <meta name="og:title" content={`${title} | Li Tzi-Mei`} />
    <meta name="og:description" content={description} />
  </Helmet>
);

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
Metadata.defaultProps = {
  title: '',
  description: 'Li Tzi-Mei',
};

export default Metadata;
